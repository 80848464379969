import { ModalController, LoadingController, NavParams, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
//import { Ng2ImgMaxService } from 'ng2-img-max';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';
@Component({
  selector: 'app-camera-picture',
  templateUrl: './camera-picture.page.html',
  styleUrls: ['./camera-picture.page.scss'],
})
export class CameraPicturePage implements OnInit {

  imageaccept:boolean=false;
  cardImageBase64: any;
  isImageSaved: boolean=false;
  imageFile: any;

  constructor(public api: ApiService, public modalController:ModalController,public loadingController:LoadingController,
    public navparams:NavParams,public alertController:AlertController,private uxhelper:UxHelpersService,
    ) { }
  imageUploadType=this.navparams.get('imageUploadType');
  ngOnInit() {
    console.log(this.imageUploadType)
  }
  async ionViewDidEnter(){
    if(this.imageUploadType==='camera'){
      await this.triggerSnapshot()
    }
  }
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  triggerSnapshot(): void {
    this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.imageFile=this.webcamImage.imageAsDataUrl;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }
  async presentAlertPhotoConfirm($event) {
    console.log($event)
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'Are you confirm to change image',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: (blah) => {
            this.imageaccept=false
            this.closeModal();
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.imageaccept=true
            this.isImageSaved=true;
            // processFile(imageInput: any) {
              const file: File = $event.files[0];
              const reader = new FileReader();
              reader.addEventListener('load', async(event: any) => {
                this.cardImageBase64= event.target.result;
              console.log(event.target.result, file);
              await this.modalController.dismiss({imageData:event.target.result});
              });
              reader.readAsDataURL(file);
              console.log(this.cardImageBase64);
            }
        }
      ]
    });
    await alert.present();
  }
  async closeModal(){
    var newData={}
    if(this.imageaccept){
      newData['isImageSaved']=this.imageaccept;
      newData['imageData']=this.imageFile;
    }else{
      newData={}
    }
    console.log(newData);
    await this.modalController.dismiss(!this.imageaccept?null:newData);
  }
}
